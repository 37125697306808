

.card {
    background-color: white !important;
    border: none !important;
    /* border-radius: 0px !important; */
}

.card:hover {
    box-shadow: #acb3ba33 0px 0px 2px 0px, #acb3ba33 0px 16px 32px -4px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	transform: scale(1.05, 1.01);
	grid-template-columns: 60px 60px;
}
.card>a {
    text-decoration: none !important;
    color: black !important
}

.cssanimation {
    animation-duration: 1s;
    animation-fill-mode: both
}

.fadeInBottom {
    animation-name: fadeInBottom
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%)
    }

    to {
        opacity: 1
    }
}

.cssanimation2 {
    animation-duration: 2s;
    animation-fill-mode: both
}

.fadeInBottom2 {
    animation-name: fadeInBottom
}

@keyframes fadeInBottom2 {
    from {
        opacity: 0;
        transform: translateY(100%)
    }

    to {
        opacity: 1
    }
}

.cssanimation3 {
    animation-duration: 3s;
    animation-fill-mode: both
}

.fadeInBottom3 {
    animation-name: fadeInBottom
}

@keyframes fadeInBottom3 {
    from {
        opacity: 0;
        transform: translateY(100%)
    }

    to {
        opacity: 1
    }
}