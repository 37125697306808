.main__div{
    padding: 2%;
}
.LeaveRequests__div{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
}
i{
    padding: 2.3px;
}
.LeaveRecords__div{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
    margin-top: 2%;
}
.filters{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}
.btn1{
    height: 35px;
}