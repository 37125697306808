.main__div{
    padding: 2%;
}
.TimeSheet{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
    margin-top: 2%;
}
.forms{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.select1{
    height: 35px;
    width: 22rem;
}
.btn1{
    height: 35px;
}
.ts-date-width{
    width:9rem
}
.ts-select{
    width:14rem
}
.width-10{
    width:10rem
}
.width-22{
    width:22rem
}

@media only screen and (min-width: 1200px) {
    .ts-date-width{
        width:10rem
    }
    .ts-select{
        width:19rem
    }
}