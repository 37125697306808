.main__div{
    padding: 2%;
   
}
.AddEmployeeTab1{
    
}
.AddEmployeeTab2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-top:15px; */
}
.AddEmployee__main{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
}
.ChangeEmpStatus{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
    margin-top: 2%;
}
.Filters__div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.EmployeeDetails{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
    margin-top: 2%;
}
.Search__div{
    padding-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.select1{
    height: "35px";
    width:11rem
}
.select2{
    width:14rem;
}
.btn1{
    height: "35px"
}
.add-employee-input1{
   width:17rem
}
.add-employee-input2{
   width:16rem
}
.add-employee-date{
   width:12rem
}
.apply-filter,.clear-filter{
    display:'inline-block'!important;
}
.apply-filter{
    margin-right:'10px'!important;
}


@media only screen and (min-width: 1200px) {
  .add-employee-input1 {
    width:20rem;
  }
  .add-employee-input2{
   width:16rem
  }
  .select1{
    height: "35px";
    width:14rem
  }
  .select2{
    height: "35px";
    width:19rem
  }
}