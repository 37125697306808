.main__div{
    padding: 2%;
}
.AddEditProjects__div{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
}
input{
    width: 22rem;
}
.project-select{
    width:40rem;
}
.project-select1{
    width:50rem;
}
textarea{
    width: 100%;
}
.AddProjects{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
    width: 100%;    
}

.EditProjects{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
    width: 100%;
    
}
.ProjectsList{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
    margin-top: 2%;
}
.Navstyle1{
    display: inline-flex;
    width: 100%;
    flex-direction: row;
}
.div1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.textarea1{
    height: 100px;
}
.project-input{
    width:24rem
}
@media only screen and (min-width: 1200px) {
    .project-select{
        width:50rem;
    }
    .project-input{
        width:29rem
    }
    .project-select1{
        width:60rem;
    }
}