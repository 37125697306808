.main__div{
    padding: 2% 
}
.Flexbox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.box{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
}
.input1{
    height: 35px;
}