.main__div{
    padding: 2% 
}
.Flexbox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.box{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
}
.date-container{
    box-shadow: 0 0 5px 2px #aaafb3;
    width:31%;
    padding:1rem;
    margin:0.5rem;
    height:8rem;
}
.dsr-absent{
    box-shadow: 0 0 5px 2px #aaafb3;
    width:65%;
    padding:1rem;
    margin:0.5rem;
}
.no-records{
    border-radius:5px;    
    width: 96%;
    margin: 0.5rem;
    text-align:center!important;
    font-weight:bold;
}
.date-width{
    width:8rem
}

@media only screen and (min-width: 1200px) {
   .date-width{
        width:10rem
    }
}