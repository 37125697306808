#loginform{
  background-color:white;
  width:100%;
  position:relative;
  margin:0 auto;
}
.login-container{
  display:inline!important;  
  border:1px solid #e5e5e7;
  padding: 30px;
  position: absolute;
  top: 10%;  
  border-radius: 10px;
  transform: translate(0%, 20%);  
  overflow: hidden;
}
.login-width{
    width:25rem;
    left: 35%;
}
.password-container-width{
    width:32rem;
    left: 32%;
}
.login-container form{
  width:100%;
}
.login-container label{
  font-weight: 500;
  font-size: 16px;
  display:block !important;
  position:relative !important;
  margin-bottom: 10px;
}
.image-wrapper{
  text-align: center;
}
.dsr-login{
  font-size: 18px;
  font-weight:500;
  margin-top:24px;
}
.dsr-subheader{
  font-size: 14px;
  font-weight:500;
}
.text-wrapper{
  margin-bottom: 20px;
}
.input-box{
  width:100%;
  padding: 10px;
  border:1px solid #e5e5e7;
  border-radius:5px;
  font-size:14px;
  box-sizing: border-box;
  margin-bottom:10px!important;
  margin-top:5px;
}
.text-danger{
  color: red;
  font-size:14px;
  font-weight:500;
}
.button-wrapper{
  margin-top:25px;
}
#forgot-password{
  font-weight:bold;
  color: #961a1d;
  border:none;
  padding:10px;  
  padding-left:0px !important;
  background-color: white;
}
#sign-in{
  float:right;
  color: #fff;  
  background-color: black!important;
  padding: 10px;
  border-radius:5px;
}
#forgot-password, #sign-in{
  font-size:16px;
  cursor:pointer;
}
a:-webkit-any-link {
    color: #0062cc!important;    
    cursor: pointer;
    font-weight:500;
    text-decoration: none!important;    
}
a:-webkit-any-link:hover{
  text-decoration: underline!important;
}