.main__div{
    padding: 2% 
}
.Flexbox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.AddRemoveAdmin{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
}
.ChangeReportingManager{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
    margin-top: 2%;
}
.NewOfficeHoliday{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
    margin-top: 2%;
}
.OfficeHoliday{
    padding: 2%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
    margin-top: 2%;
}
.text-1{
    width:14rem;
}

@media only screen and (min-width: 1200px) {
    .text-1{
        width:18rem;
    }
}