select, textarea{
    border:1px solid #ccc;
    outline:none;
}
input{
    width:18rem;
    outline:none;
}
.auto-generate{
    border: 1px solid #dedddd;
    font-size: 12px;
    border-radius: 5px;
    background-color: white;
    margin-left:10px;
}
.width-100{
    width:100%;
}
select{
    height: 35px;
    width:16rem;
    outline:none;
}
.read-only{
    background-color:#eeeeee;
}
.set-date-width{
    width:11rem;
    outline:none;
}
.search-manually{
    font-size: 12px;
    height: 25px;
    width: 12rem!important;
}
.cdb-select{
    border:1px solid #ccc;
    margin-bottom:10px;
    width:100%;
}
.Toastify__toast-container--top-center {
    left: 55%!important;
}
.Toastify__toast-container{
    width: 35% !important;
}
.edit-btn{
    border:none;
    border-radius:5px;
}
.delete-btn{
    border:none;
    border-radius:5px;
}
.search-input{
    margin-bottom: 0.5rem!important;
    width: 12rem;
    height: 1.5rem;
    font-size: 14px;
}
.export-btn{
    background-color:'#6366f1'!important;
    border:'#6366f1'!important;
    color:"white"!important;
    float:"right"!important;
}
.CDBBtn{
    margin-top: 25px;
}
input{
    border: 1px solid #ccc;
    padding:5px;
}  
input::-webkit-inner-spin-button, 
input::-webkit-outer-spin-button {  
   opacity: 1;
   height:auto!important;
}
.form-control{
    border-radius:0.2rem!important;
}
.spacing{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.center-align{
    display: "flex";
    align-items: "center";
    justify-content: "center"
}
.break{
    flex-basis: "100%"!important;
    height: 0;
}

@media only screen and (min-width: 1200px) {
    select{
        height: 35px;
        width:20rem;
        outline:none;
    }
    .set-date-width{
        width:13rem;
        outline:none;
    }
    .project-select{
        width:50rem;
    }
}