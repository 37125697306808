.Logout__Text {
    display: none;
    /* height: 10px; */
    position: absolute;
    width: 60px;
    margin-left: 7%;
    margin-top: -2px;
    border:"1px solid red";
    background-color: #fff;
    color: #333;
    border-radius: 2px;
    font-size: 12px;
    text-align: center;

}

.Logout:hover+.Logout__Text {
    display: block;
}


.navIcons {
    /* Other styles for .navIcons */
    transition: font-size  ease; /* Smooth transition */
}

.navIcons:hover {
    font-size: 20px; /* New font size on hover */
}

