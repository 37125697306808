.Main__div{
    
    /* justify-content: space-between; */
    
}
.ApplyLeave__div{
    padding: 2%;
    width: 44%; 
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
    margin: 2%;   
}
.ApplyLeave__DateRange{
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
}
.Applyleave__Date{
    margin-bottom: 50px;
}
.NoOfDays__div{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.CDBSelect{
border: 1px solid black;
margin-bottom: 20px;
height: 35px;
}
.Reason__div{
    display: flex;
    flex-direction: column;
}
textarea{
height: 100px;
}
.CDBBtn{
margin-top: 25px;

}
h4{
   margin-left: 0%;
   margin-bottom: 20px;
}
.SearchInput{
    margin-bottom: 20px; 
}
h2,h4{
font-weight: 600;
}
label{
    font-size: 13px;
    color: rgb(9,8,8);
}
.UpComingLeaves__div{    
    padding: 2%;
    width: 50%;
    border: 1px solid grey;
    box-shadow: 0 0 5px 2px #aaafb3;
    margin: 2% 0px;
}
