.distributionlist-container{
    background-color: white;
    padding: 20px;
    margin: 10px auto;
    box-shadow: 0 0 5px 2px #aaafb3;
    border-radius: 3px;
    text-align: center;
}

.myprojects-container{
    box-shadow: 0 0 5px 2px #aaafb3;
    margin: 10px;
}
.width-80{
    width:80%
}
.sent-items-container{
    box-shadow: 0 0 5px 2px #aaafb3;
    width:31%;
    padding:1rem;
}
.card-container{
    box-shadow: 0 0 5px 2px #aaafb3;
    padding:1rem;
}
.inner-font{
    font-size: 13px;
}
.draft-container{
    background-color: white;
    padding: 20px;
    margin: 15px;
    box-shadow: 0 0 5px 2px #aaafb3;
    border-radius: 2px;
}
.timesheet-container{
    background-color: white;
    padding: 20px;
    margin-top: 30px;
    box-shadow: 0 0 5px 2px #aaafb3;
    border-radius: 2px;
    text-align: center;
    width: 100%;
}
.box-shadow{
    box-shadow: 0 0 5px 2px #aaafb3;
}
.projectName{
    width: 100%;
}
.flexDisplay{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.widthSet{
    width: 200px;
}
.TextEditor{
    height: 200px;
}
.DSRArchive__div{
    padding: 1rem; 
    border-radius:5px;    
    box-shadow:0 0 5px 2px #aaafb3!important;
    width: 97.5%;
    margin: 0.5rem;
    margin-top: 1rem;
}
.container{
    margin-top:10px;
}
.card-wrapper{
    display:"inline-block";
    width:"33%";
}
